<div class="table-view">
  <p-toolbar>
    <ng-template pTemplate="left">
      <p-button
        severity="success"
        label="Add Rule"
        icon="pi pi-plus"
        (click)="addRule()"/>
    </ng-template>
    <ng-template pTemplate="right">
      <span class="p-input-icon-left search-filter">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          [(ngModel)]="searchValue"
          (input)="dt.filterGlobal($event.target.value, 'contains')"
          placeholder="Search..."/>
      </span>
    </ng-template>
  </p-toolbar>
  <p-table
    #dt
    class="custom-table"
    [value]="sharedOfferSetsRules"
    styleClass="p-datatable-gridlines"
    [rowHover]="true"
    [globalFilterFields]="globalFilterFields">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="ruleName">
          Rule Name
          <p-sortIcon field="ruleName"/>
        </th>
        <th pSortableColumn="sourceAccount.name">
          Source Account
          <p-sortIcon field="sourceAccount.name"/>
          <p-columnFilter
            field="sourceAccount"
            matchMode="in"
            display="menu"
            [showMatchModes]="false"
            [showOperator]="false"
            [showAddButton]="false">
            <ng-template pTemplate="filtericon">
              <i class="pi pi-filter custom-filter-icon"></i>
            </ng-template>
            <ng-template pTemplate="header">
              <div class="source-account-filter">
                <span><b>Select Source Account</b></span>
              </div>
            </ng-template>
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="sourceAccounts" placeholder="Any"
                             (onChange)="filter($event.value)" optionLabel="name">
                <ng-template let-option pTemplate="item">
                  <div>
                    <span>{{ option.name }}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>Account Filters</th>
        <th>Offer Set Filters</th>
        <th>Edit/Delete</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rule let-ruleIndex="rowIndex">
      <tr>
        <td>
          <label (mouseover)="op.toggle($event);" (mouseout)="op.hide()">{{ rule.ruleName }}
            <span class="pi pi-info-circle"></span></label>
          <p-overlayPanel #op>
            <div class="flex flex-column gap-3 w-25rem">
              <div>
                <span>Last Modified By:
                  <b>{{ rule.lastModifiedUser }}</b>
                </span>
                <br>
                <span>Last Modified:
                  <b>{{ rule.lastModified | date:'shortDate':'UTC' }}</b>
                </span>
                <br>
                <span>Created:
                  <b>{{ rule.created  | date:'shortDate': 'UTC' }}</b>
                </span>
              </div>
            </div>
          </p-overlayPanel>
        </td>
        <td>{{ rule.sourceAccount.name }}</td>
        <td>N/A</td>
        <td>N/A</td>
        <td>
          <p-button
            icon="pi pi-pencil"
            severity="success"
            class="p-button-rounded p-button-success"
            (click)="editRule(rule)">
          </p-button>
          <p-button
            icon="pi pi-trash"
            severity="danger"
            class="p-button-rounded p-button-danger search-filter"
            (click)="deleteRule($event, rule)">
          </p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td class="not-found-field" colspan="7"><i>No Rules Found.</i></td>
      </tr>
    </ng-template>
  </p-table>
</div>
