<auto-main-navigation [accountId]=""></auto-main-navigation>
<p-panel>
  <p-header>
    <h1>Shared Offer Set Rules</h1>
  </p-header>
  <p-confirmDialog></p-confirmDialog>
  <auto-shared-offer-set-rules-table
    [sharedOfferSetsRules]="sharedOfferSetsRules"
    [sourceAccounts]="sourceAccounts">
  </auto-shared-offer-set-rules-table>
</p-panel>
<auto-footer></auto-footer>
