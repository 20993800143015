import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {SharedOfferSetRuleService} from '../../../../../service/shared-offer-set-rule.service';
import {OfferSetSharingRuleModel} from '../../../../../model/offer-set-sharing-rule.model';
import {MessageService} from 'primeng/api';
import {EventEmitterService} from '../../../../../service/event-emitter.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'auto-shared-offer-set-rules',
  templateUrl: './shared-offer-set-rules.component.html',
  styleUrl: './shared-offer-set-rules.component.scss'
})
export class SharedOfferSetRulesComponent implements OnInit {

  sharedOfferSetsRules: OfferSetSharingRuleModel[] = [];
  sourceAccounts = [];
  private eventSubscription: Subscription;

  constructor(
    private titleService: Title,
    private offerSetRuleService: SharedOfferSetRuleService,
    private messageService: MessageService,
    private eventEmitterService: EventEmitterService
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('MPOP Shared Offer Set Rule Management');
    this.loadSharedOfferSetRules();
    this.eventSubscription = this.eventEmitterService.event$.subscribe(
      eventName => {
        if (eventName === 'loadSharedOfferSetRules') {
          this.loadSharedOfferSetRules();
        }
      }
    );
  }

  loadSharedOfferSetRules(): void {
    this.offerSetRuleService.getSharedOfferSetRules().subscribe({
      next: (rules) => {
        this.sharedOfferSetsRules = rules;
        this.populateSourceAccounts();
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load shared offer set rules.'
        });
      }
    });
  }

  /**
   * Populate the source accounts array with the source accounts from the shared offer set rules
   */
  populateSourceAccounts() {
    this.sharedOfferSetsRules.forEach(rule => {
      this.sourceAccounts.push(rule.sourceAccount);
    });
  }
}
