import {Component, EventEmitter, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {SharedOfferSetRuleService} from '../../../../../service/shared-offer-set-rule.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {OfferSetSharingRuleFilterDefinitionModel} from '../../../../../model/offer-set-sharing-rule-filter-definition.model';
import {DynamicDialogRef, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {OfferSetSharingRuleModel} from '../../../../../model/offer-set-sharing-rule.model';
import {OfferSetSharingRuleRequestModel} from '../../../../../model/offer-set-sharing-rule-request.model';
import {OfferSetSharingRuleFilterModel} from '../../../../../model/offer-set-sharing-rule-filter.model';
import {EventEmitterService} from '../../../../../service/event-emitter.service';
import {AccountService} from '../../../../../service/account.service';
import {DashboardAccountInterface} from '../../../../../model/dashboard-account.interface';

@Component({
  selector: 'auto-shared-offer-set-rules-entry',
  templateUrl: './shared-offer-set-rules-entry.component.html',
  styleUrls: ['./shared-offer-set-rules-entry.component.scss']
})
export class SharedOfferSetRulesEntryComponent {
  ruleForm: FormGroup;
  accountFilters: any[] = [];
  offerFilters: any[] = [];
  ruleData?: OfferSetSharingRuleModel;
  sourceAccountOptions: any[] = [];
  @Output() refreshData: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private formBuilder: FormBuilder,
    private offerSetRuleService: SharedOfferSetRuleService,
    private messageService: MessageService,
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private eventEmitterService: EventEmitterService,
    private confirmationService: ConfirmationService,
    private accountService: AccountService
  ) {
    this.ruleForm = this.formBuilder.group({
      ruleName: [''],
      sourceAccount: [''],
      offerSetFilter: this.formBuilder.array([]),
      accountFilter: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    this.ruleData = this.config.data;
    this.loadFilterData();
    this.loadAccountData();
    if (this.ruleData) {
      this.populateForm(this.ruleData);
    }
  }

  /**
   * Populate the form with the data from the rule
   */
  populateForm(rule: OfferSetSharingRuleModel) {
    this.ruleForm.patchValue({
      ruleName: rule.ruleName,
      sourceAccount: rule.sourceAccount['id']
    });

    (this.ruleForm.get('offerSetFilter') as FormArray).clear();
    (this.ruleForm.get('accountFilter') as FormArray).clear();

    rule.offerSetSharingRuleFilters.forEach(filter => {
      const filterGroup = this.formBuilder.group({
        propertyValue: [filter.offerSetSharingRuleFilterDefinitionId],
        selectedOperator: [filter.operator],
        value: [filter.value]
      });
      if (filter.filterType === 'account') {
        (this.ruleForm.get('accountFilter') as FormArray).push(filterGroup);
      } else if (filter.filterType === 'offerSet') {
        (this.ruleForm.get('offerSetFilter') as FormArray).push(filterGroup);
      }
    });
  }

  /**
   * Load the account data from the service
   */
  loadAccountData() {
    //TODO LC-11406: use NcsAccounts Endpoint instead of dashboard accounts
    this.accountService.getDashboardAccounts().subscribe({
      next: (accounts: DashboardAccountInterface[]) => {
        this.sourceAccountOptions = accounts
          .filter(account => account.active)
          .map(account => ({label: account.name, value: account.accountId}));
        this.sourceAccountOptions.sort((firstItem, secondItem) => firstItem.label.localeCompare(secondItem.label));
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load MPOP accounts.'
        });
      }
    });
  }

  /**
   * Load the filter data from the service
   */
  loadFilterData(): void {
    this.offerSetRuleService.getOfferSetSharingRuleFilterDefinition().subscribe({
      next: (filters: OfferSetSharingRuleFilterDefinitionModel[]) => {
        this.accountFilters = filters
          .filter(filter => filter.filterType === 'account')
          .map(filter => ({label: filter.filterName, value: filter.id, type: filter.dataType}));

        this.offerFilters = filters
          .filter(filter => filter.filterType === 'offerSet')
          .map(filter => ({label: filter.filterName, value: filter.id, type: filter.dataType}));
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load filter definitions.'
        });
      }
    });
  }

  /**
   * Add a new filter to the form
   */
  saveRule() {
    let offerSetSharingRuleRequest = this.buildOfferSetSharingRuleRequest();
    this.offerSetRuleService.updateSharedOfferSetRule(offerSetSharingRuleRequest).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Rule saved successfully.'
        });
        this.dialogRef.close();
        this.ruleForm.markAsPristine();
        this.eventEmitterService.emitEvent('loadSharedOfferSetRules');
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save rule.'
        });
      }
    );
  }

  /**
   * Build the request object for the API call
   */
  buildOfferSetSharingRuleRequest(): OfferSetSharingRuleRequestModel {
    let offerSetSharingRuleRequest = new OfferSetSharingRuleRequestModel();
    if (this.ruleData) {
      offerSetSharingRuleRequest.offerSetSharingRuleId = this.ruleData.id;
    }
    offerSetSharingRuleRequest.ruleName = this.ruleForm.get('ruleName').value;
    offerSetSharingRuleRequest.sourceAccountId = this.ruleForm.get('sourceAccount').value;
    offerSetSharingRuleRequest.offerSetSharingRuleFilters = this.getSharingRulesFiltersForRequest();

    return offerSetSharingRuleRequest;
  }

  /**
   * Get the filters from the form and convert them to the format required for the request
   */
  private getSharingRulesFiltersForRequest(): any[] {
    let filters = [];
    this.ruleForm.get('offerSetFilter').value.forEach(filter => {
      let offerSetSharingRuleFilter = this.buildOfferSetSharingRuleFilter(filter, 'offerSet');
      filters.push(offerSetSharingRuleFilter);
    });
    this.ruleForm.get('accountFilter').value.forEach(filter => {
      let offerSetSharingRuleFilter = this.buildOfferSetSharingRuleFilter(filter, 'account');
      filters.push(offerSetSharingRuleFilter);
    });

    return filters;
  }

  /**
   * Build the filter object for the request
   */
  buildOfferSetSharingRuleFilter(filter: any, filterType: string): OfferSetSharingRuleFilterModel {
    let offerSetSharingRuleFilter = new OfferSetSharingRuleFilterModel();
    offerSetSharingRuleFilter.offerSetSharingRuleFilterDefinitionId = filter.propertyValue;
    offerSetSharingRuleFilter.value = filter.value;
    offerSetSharingRuleFilter.operator = filter.selectedOperator;
    offerSetSharingRuleFilter.filterType = filterType;

    return offerSetSharingRuleFilter;
  }

  /**
   * Close the modal
   */
  closeModal() {
    if (!this.ruleForm.dirty) {
      this.dialogRef.close();
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure you want to close without saving changes?',
        header: 'Unsaved Changes',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.dialogRef.close();
        },
        reject: () => {}
      });
    }
  }

  /**
   * Check if the form is invalid
   */
  isInvalidForm() {
    return this.ruleForm.get('ruleName').value === '' || this.ruleForm.get('sourceAccount').value === '';
  }
}
