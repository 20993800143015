export class AccountInventoryField {
  id: number;
  name: string;
  niceName: string;
  ncsAccountId: number;
  inventoryFieldId: number;
  colNum: string;
  isMultiValue: boolean;
  multiValueSeparator: string;
  inventoryProviderId: number;

  constructor() {
    this.id = null;
    this.name = null;
    this.niceName = null;
    this.ncsAccountId = null;
    this.inventoryFieldId = null;
    this.colNum = null;
    this.isMultiValue = null;
    this.multiValueSeparator = null;
    this.inventoryProviderId = null;
  }
}
