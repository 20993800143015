import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {throwError} from 'rxjs';
import {CookieService} from "./cookie.service";

export abstract class AbstractHttpService {
  protected contentTypeJsonHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  protected contentTypeFormUrlEncodedHeaders = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  protected contentTypeJsonHeadersForPatch = new HttpHeaders().set('Content-Type', 'application/merge-patch+json');
  protected acceptJsonHeaders = new HttpHeaders().set('Accept', 'application/json');

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    let errorMessage = 'Request failed.';
    if (error.status >= 400 && error.status <= 500) {
      errorMessage = error.error;
      if (error.status === 401) {
        const cookieService = new CookieService();
        cookieService.clearSessionCookies();
      }
    }
    return throwError(errorMessage);
  }
}
