import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-shared-offer-set-rules-filter',
  templateUrl: './shared-offer-set-rules-filter.component.html',
  styleUrls: ['./shared-offer-set-rules-filter.component.scss']
})
export class SharedOfferSetRulesFilterComponent implements OnInit {
  @Input() ruleForm!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() propertyValueFilters: any[] = [];

  filterOperators = [
    { label: 'Greater Than', value: 'greater than', filterType: 'number' },
    { label: 'Less Than', value: 'less than', filterType: 'number' },
    { label: 'Greater Than or Equal', value: 'greater than or equal to', filterType: 'number' },
    { label: 'Less Than or Equal', value: 'less than or equal to', filterType: 'number' },
    { label: 'Equals', value: 'equal to', filterType: 'number' },
    { label: 'Not Equal', value: 'not equal to', filterType: 'number' },
    { label: 'Is', value: 'is', filterType: 'string' },
    { label: 'Is Not', value: 'is not', filterType: 'string' },
    { label: 'Contains', value: 'contains', filterType: 'string' },
    { label: 'Not Contains', value: 'not contains', filterType: 'string' },
    { label: 'Like', value: 'like', filterType: 'string' },
    { label: 'Not Like', value: 'not like', filterType: 'string' },
    { label: 'In', value: 'in', filterType: 'string' },
    { label: 'Not In', value: 'not in', filterType: 'string' }
  ];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (!this.ruleForm.get(this.controlName)) {
      this.ruleForm.addControl(this.controlName, this.formBuilder.array([]));
    }
  }

  /**
   * Get the FormArray of rules from the parent FormGroup
   */
  get rules(): FormArray {
    return this.ruleForm.get(this.controlName) as FormArray;
  }

  /**
   * Add a new rule Filter to the FormArray
   */
  addRule() {
    const ruleGroup = this.formBuilder.group({
      propertyValue: [''],
      selectedOperator: [''],
      value: ['']
    });

    this.rules.push(ruleGroup);
  }

  /**
   * Delete a rule Filter from the FormArray
   * @param index
   */
  deleteRule(index: number) {
    if (this.rules.length > index) {
      this.rules.removeAt(index);
    }
  }

  /**
   * Get the available operator options based on the selected property type
   */
  getOperatorOptions(propertyId: string) {
    let result = this.filterOperators;
    const property = this.propertyValueFilters.find((property) => property.value === propertyId);
    if (property) {
      if (property.type === 'number') {
        result = this.filterOperators.filter((operator) => operator.filterType === 'number');
      } else if (property.type === 'string') {
        result = this.filterOperators.filter((operator) => operator.filterType === 'string');
      }
    }
    return result;
  }
}
