import {OfferSetSharingRuleFilterModel} from './offer-set-sharing-rule-filter.model';

export class OfferSetSharingRuleRequestModel {
  offerSetSharingRuleId: number;
  sourceAccountId: number;
  ruleName: string;
  offerSetSharingRuleFilters: OfferSetSharingRuleFilterModel[];

  constructor() {
    this.offerSetSharingRuleId = null;
    this.sourceAccountId = null;
    this.ruleName = null;
    this.offerSetSharingRuleFilters = null;
  }
}
