import {Component, Input, OnInit} from '@angular/core';
import {OfferSetSharingRuleModel} from '../../../../../model/offer-set-sharing-rule.model';
import {DialogService} from 'primeng/dynamicdialog';
import {SharedOfferSetRulesEntryComponent} from '../shared-offer-set-rules-entry/shared-offer-set-rules-entry.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {SharedOfferSetRuleService} from '../../../../../service/shared-offer-set-rule.service';

@Component({
  selector: 'auto-shared-offer-set-rules-table',
  templateUrl: './shared-offer-set-rules-table.component.html',
  styleUrl: './shared-offer-set-rules-table.component.scss',
  providers: [DialogService]
})
export class SharedOfferSetRulesTableComponent implements OnInit {
  @Input() sharedOfferSetsRules: OfferSetSharingRuleModel[] = [];
  @Input() sourceAccounts: any[] = [];
  searchValue: string;
  globalFilterFields = ['ruleName', 'sourceAccount.name'];

  constructor(
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private sharedOfferSetRuleService: SharedOfferSetRuleService
  ) {
  }

  ngOnInit(): void {
  }

  /**
   * Open the dialog to create a new rule
   */
  addRule() {
    this.dialogService.open(SharedOfferSetRulesEntryComponent, {
      header: 'Create Offer Set Sharing Rule',
      width: '80vw',
      closable: false,
      modal: true
    });
  }

  /**
   * Open the dialog to edit an existing rule
   */
  editRule(rule: OfferSetSharingRuleModel) {
    this.dialogService.open(SharedOfferSetRulesEntryComponent, {
      header: 'Edit Offer Set Sharing Rule',
      width: '80vw',
      modal: true,
      closable: false,
      data: rule
    });
  }

  /**
   * Delete a Offer set sharing rule
   */
  deleteRule(event: Event, rule: OfferSetSharingRuleModel) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to delete this rule and all associated filters?',
      header: 'Confirm Deletion of Rule: ' + (rule.ruleName),
      icon: 'pi pi-info-circle',
      acceptLabel: 'Delete',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel',
      accept: () => {
        this.sharedOfferSetRuleService.deleteSharedOfferSetRule(rule.id).subscribe(
          () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Rule Deleted'
            });
            this.sharedOfferSetsRules = this.sharedOfferSetsRules.filter(r => r.id !== rule.id);
          },
          error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to delete rule'
            });
          }
        );
      }
    });
  }
}
