import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {FormGroup, UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {NavigationService} from '../../../../service/navigation.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {InventoryMappingService} from '../../../../service/inventory-mapping.service';
import {InventoryMappingRequestModel} from '../../../../model/inventory-mapping-request.model';
import {AccountInventoryField} from '../../../../model/account-inventory-field';
import {AccountService} from '../../../../service/account.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'auto-inventory-mapping',
  templateUrl: './inventory-mapping.component.html',
  styleUrl: './inventory-mapping.component.scss'
})
export class InventoryMappingComponent implements OnInit, OnDestroy {

  accountId: number;
  settingsSideNav: MenuItem[];
  inventoryMappingForm: FormGroup;
  offerFieldOptions = [];
  inventoryHeaders = [];
  inventoryFields = [];
  separators = ['|', ',', ' '];
  inventoryMappingFields = [];
  isFieldLoaded: boolean = false;
  duplicateFields: Set<number> = new Set();
  inventoryProviderId: number;
  requestProcessing: boolean = false;
  private routerSubscription: Subscription;

  constructor(private navigationService: NavigationService,
              private route: ActivatedRoute,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private inventoryMappingService: InventoryMappingService,
              private messageService: MessageService,
              private accountService: AccountService,
              private confirmationService: ConfirmationService
  ) {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart
        && this.inventoryMappingForm.dirty
        && !confirm('You have unsaved changes. Do you really want to leave?')
      ) {
        this.router.navigateByUrl(this.router.url);
      }
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.initNavigation();
      this.getInventoryFields();
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe(); // Prevent memory leaks
  }

  /**
   * Get inventory mapping fields
   */
  getInventoryMappingFields(accountId: number) {
    this.requestProcessing = true;
    this.inventoryMappingService.getInventoryMappingFields(accountId)
      .subscribe({
          next: (inventoryMappingFields) => {
            this.inventoryMappingFields = inventoryMappingFields;
            this.accountService.getAccount(accountId).subscribe((account) => {
              this.inventoryProviderId = account.inventoryProviderId;
              this.initForm();
              this.isFieldLoaded = true;
              this.requestProcessing = false;
            });
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to load inventory mapping.'
            });
            this.requestProcessing = false;
          }
        }
      );
  }

  /**
   * Get fields
   */
  get fields(): UntypedFormArray {
    return this.inventoryMappingForm.get('fields') as UntypedFormArray;
  }

  /**
   * Initialize form group array
   */
  private initForm() {
    this.inventoryMappingForm = this.formBuilder.group({
      fields: this.formBuilder.array([]),
    });
    this.initializeMappingFields();
  }

  /**
   * Init navigation
   */
  initNavigation() {
    this.navigationService.getMenuItems(this.accountId, 'inventory-settings-view-side-menu.configuration.json')
      .then((navItems) => {
        this.settingsSideNav = navItems;
      });
  }

  /**
   * Get inventory fields
   */
  private getInventoryFields() {
    this.inventoryMappingService.getInventoryFields()
      .subscribe({
          next: (inventoryFields) => {
            this.inventoryFields = inventoryFields;
            this.inventoryFields.forEach((field) => {
              this.offerFieldOptions.push({
                label: field.name,
                value: field.name,
                hidden: this.isRequiredField(field.name)
              });
            });
            this.offerFieldOptions.sort((firstOptions, secondOption) => firstOptions.label.localeCompare(secondOption.label));
            this.getInventoryHeaders();
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to load inventory fields.'
            });
          }
        }
      );
  }

  /**
   * Update inventory mapping
   */
  updateInventoryMapping() {
    const inventoryMappingRequestModel = this.buildInventoryMappingRequestModel();
    this.inventoryMappingService.updateInventoryMapping(inventoryMappingRequestModel)
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Inventory mapping updated successfully.'
          });
          this.getInventoryMappingFields(this.accountId);
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to update inventory mapping.'
          });
        }
      });
  }

  /**
   * Build inventory mapping request model
   */
  private buildInventoryMappingRequestModel() {
    const inventoryMappingRequestModel = new InventoryMappingRequestModel();
    inventoryMappingRequestModel.accountId = this.accountId;
    inventoryMappingRequestModel.inventoryMappingFields = [];
    inventoryMappingRequestModel.deletedInventoryMappingFields = [];

    this.fields.controls.forEach((field) => {
      const accountInventoryField = new AccountInventoryField();
      if (field.get('id')) {
        accountInventoryField.id = field.get('id').value;
      }
      accountInventoryField.name = field.get('name').value;
      accountInventoryField.colNum = this.inventoryHeaders.indexOf(field.get('inventoryHeader').value).toString();
      accountInventoryField.isMultiValue = field.get('isMultiValue').value;
      accountInventoryField.multiValueSeparator = field.get('multiValueSeparator').value;
      accountInventoryField.inventoryFieldId = this.inventoryFields.find((inventoryField) => inventoryField.name === accountInventoryField.name).id;
      accountInventoryField.inventoryProviderId = this.inventoryProviderId;
      accountInventoryField.niceName = this.inventoryFields.find((inventoryField) => inventoryField.name === accountInventoryField.name).niceName;
      accountInventoryField.ncsAccountId = this.accountId;
      inventoryMappingRequestModel.inventoryMappingFields.push(accountInventoryField);
    });

    this.inventoryMappingFields.forEach((inventoryMappingField) => {
      if (!this.fields.controls.find((field) => field.get('id').value === inventoryMappingField.id)) {
        inventoryMappingRequestModel.deletedInventoryMappingFields.push(inventoryMappingField);
      }
    });

    return inventoryMappingRequestModel;
  }

  /**
   * Open reset to default confirmation
   */
  openResetToDefaultConfirmation() {
    this.confirmationService.confirm({
      message: 'Do you want to reset Inventory Mapping to default?',
      header: 'Reset to Default',
      icon: 'pi pi-icon',
      acceptLabel: 'Reset',
      rejectLabel: 'Cancel',
      accept: () => {
        this.resetInventoryMapping();
      },
      reject: () => {}
    });
  }

  /**
   * Reset inventory mapping
   */
  resetInventoryMapping() {
    this.requestProcessing = true;
    this.inventoryMappingService.resetInventoryMapping(this.accountId)
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Inventory mapping reset successfully.'
          });
          this.getInventoryMappingFields(this.accountId);
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to reset inventory mapping.'
          });
          this.requestProcessing = false;
        }
      });
  }

  /**
   * Get inventory headers
   */
  private getInventoryHeaders() {
    this.inventoryMappingService.getInventoryHeaders(this.accountId).subscribe(
      {
        next: (inventoryHeaders) => {
          this.inventoryHeaders = inventoryHeaders;
          this.getInventoryMappingFields(this.accountId);
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            life: 10000000, // life is set to a large number to ensure a user knows that an inventory file is required
            detail: 'No inventory file for account found.'
          });
        }
      }
    )
  }

  /**
   * Check if field is required
   */
  isRequiredField(name) {
    return !!this.inventoryFields.find((field) => field.name === name && field.required);
  }

  /**
   * Check if field is duplicate
   */
  hasDuplicateField() {
    this.duplicateFields.clear();
    const fieldValues = this.fields.controls.map((field, index) => ({
      value: field.get('name')?.value,
      index: index
    }));

    fieldValues.forEach((field, index) => {
      if (field.value && fieldValues.filter(f => f.value === field.value).length > 1) {
        this.duplicateFields.add(index);
      }
    });
  }

  /**
   * Initialize mapping fields
   */
  private initializeMappingFields() {
    this.inventoryMappingFields = this.inventoryMappingFields.sort((first, second) => {
      return (+this.isRequiredField(second.name)) - (+this.isRequiredField(first.name));
    });
    this.inventoryMappingFields.forEach(
      (field) => {
        this.fields.push(this.formBuilder.group({
          id: [field.id],
          name: [{value: field.name, disabled: this.isRequiredField(field.name)}],
          inventoryHeader: [this.inventoryHeaders[field.colNum]],
          inventoryFieldId: [field.inventoryFieldId],
          isMultiValue: [field.isMultiValue],
          niceName: [field.niceName],
          multiValueSeparator: [field.multiValueSeparator],
          inventoryProviderId: [field.inventoryProviderId]
        }));
      }
    );
  }

  /**
   * Get filtered offer field options
   */
  getFilteredOfferFieldOptions(selectedValue: string) {
    return this.offerFieldOptions.filter((option) => !option.hidden || option.value === selectedValue);
  }

  /**
   * Add inventory mapping
   */
  addInventoryMapping() {
    this.fields.push(this.formBuilder.group({
      name: [''],
      inventoryHeader: [''],
      isMultiValue: [false],
      multiValueSeparator: ['']
    }));
  }

  /**
   * Delete mapping field
   */
  deleteMappingField(index: number) {
    if (this.fields.length > index) {
      this.fields.removeAt(index);
      this.inventoryMappingForm.markAsDirty();
    }
    this.hasDuplicateField();
  }
}
