export class OfferSetSharingRuleFilterModel {
  offerSetSharingRuleId: number;
  offerSetSharingRuleFilterDefinitionId: number;
  filterType: string;
  operator: string;
  value: string;

  constructor() {
    this.offerSetSharingRuleId = null;
    this.offerSetSharingRuleFilterDefinitionId = null;
    this.filterType = null;
    this.operator = null;
    this.value = null;
  }
}
