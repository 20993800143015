<p-accordion class="accordion-wrapper">
  <p-accordionTab [header]="label" [selected]="true">
    <div class="p-grid form-row">
      <div class="p-col-2">
        <button pButton type="button" class="p-button-success" label="Add" (click)="addRule()"></button>
      </div>
      <div class="p-col-8"></div>
    </div>
    <div [formGroup]="ruleForm" autoTrackUnsavedChanges>
      <div [formArrayName]="controlName">
        <div *ngIf="rules.length === 0" class="p-text-center p-mt-2">
          <p><i>No Rules Created Yet.</i></p>
        </div>
        <div *ngIf="rules.length > 0">
          <div class="p-grid p-justify-center form-row">
            <div class="p-col-2"><strong>Property Value</strong></div>
            <div class="p-col-2"><strong>Operator</strong></div>
            <div class="p-col-2"><strong>Value</strong></div>
            <div class="p-col-1"></div>
          </div>
          <div class="p-grid p-justify-center form-row" *ngFor="let rule of rules.controls; let idx = index"
               [formGroupName]="idx">
            <div class="p-col-2">
              <p-dropdown [options]="propertyValueFilters" formControlName="propertyValue"
                          placeholder="Select Property"></p-dropdown>
            </div>
            <div class="p-col-2">
              <p-dropdown
                [options]="getOperatorOptions(rule.get('propertyValue').value)"
                formControlName="selectedOperator"
                placeholder="Select Operator">
              </p-dropdown>
            </div>
            <div class="p-col-2">
              <input pInputText formControlName="value" placeholder="Enter Value"/>
            </div>
            <div class="p-col-1">
              <button pButton type="button" class="p-button-danger p-button-icon" (click)="deleteRule(idx)">
                <i class="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
