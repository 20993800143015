export class InventoryMappingRequestModel {
  accountId: number;
  inventoryMappingFields: any[];
  deletedInventoryMappingFields: any[];

    constructor() {
        this.accountId = null;
        this.inventoryMappingFields = null;
        this.deletedInventoryMappingFields = null;
    }
}
